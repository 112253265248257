@import "../../styles/typography";

$tooltip-z-index: 9999999999999999;

.tooltip {
  position: relative;
  display: inline-block;
  border-radius: var(--tooltip-border-radius, var(--border-radius-small));
  padding: var(--tooltip-padding, #{var(--spacing-small) var(--spacing-medium)});
  box-shadow: var(--box-shadow-medium);
  @include font-input();
  max-width: var(--tooltip-max-width, 50vw);
  word-break: break-word;

  &.withMaxWidth {
    --tooltip-max-width: 240px;
  }
}

.tooltip.paddingSizeMd {
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-medium);
  @include font-input();
}

.tooltip a.tooltipWhiteLink {
  color: #fff;
}

.dark,
.arrow.dark {
  background-color: var(--inverted-color-background);
  color: var(--text-color-on-inverted);
}

.white,
.arrow.white {
  background-color: var(--color-snow_white);
  color: var(--color-blackish);
  box-shadow: var(--box-shadow-medium);
}

.primary,
.arrow.primary {
  background-color: var(--primary-color);
  color: var(--text-color-on-primary);
}

.success,
.arrow.success {
  background-color: var(--positive-color);
  color: var(--text-color-on-primary);
}

.surface,
.arrow.surface {
  background-color: #5b607a;
  color: var(--color-snow_white);
}

.private,
.arrow.private {
  background-color: var(--private-color);
  color: var(--text-color-on-primary);
}

.share,
.arrow.share {
  background-color: var(--shareable-color);
  color: var(--text-color-on-primary);
}

.error,
.arrow.error {
  background-color: var(--negative-color);
  color: var(--text-color-on-primary);
}
