@import "../../../styles/typography";

.menu {
  margin: unset;
  padding: unset;
  position: relative;
}

.menu:focus {
  outline: none;
}

.small {
  width: 200px;
}

.medium {
  width: 220px;
}

.large {
  width: 240px;
}
