.button {
  display: inline-flex;
  border-radius: var(--border-radius-small);
  transition: var(--motion-productive-short) transform,
    var(--motion-productive-medium) var(--motion-timing-transition) min-width;
}

.button .mainButton {
  margin-right: 0;
}

.button .secondaryButton {
  margin-left: 0;
}

.secondaryButtonWrapper .secondaryButtonIconWrapper {
  display: inline-flex;
  padding: 0 4px;
}

.active {
  transform: scale(0.95);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.kindTertiary.colorPrimary.mainActive .mainButton {
  background-color: var(--primary-selected-color);
}

.kindTertiary.colorPrimary.mainActive .secondaryButton {
  background-color: var(--primary-selected-color);
}

.kindTertiary.colorPrimary.mainActive.splitContentOpen.splitContentOpen .secondaryButton,
.kindTertiary.colorPrimary.mainActive.splitContentOpen.splitContentOpen .secondaryButton:hover,
.kindTertiary.colorPrimary.mainActive.hovered.splitContentOpen .secondaryButton,
.kindTertiary.colorPrimary.mainActive.hovered.splitContentOpen .secondaryButton:hover {
  color: var(--primary-color);
}

.kindTertiary.colorBrand.mainActive .mainButton {
  background-color: var(--brand-selected-color);
}

.kindTertiary.colorBrand.mainActive .secondaryButton {
  background-color: var(--brand-selected-color);
}

.kindTertiary.colorBrand.mainActive.splitContentOpen.splitContentOpen .secondaryButton,
.kindTertiary.colorBrand.mainActive.splitContentOpen.splitContentOpen .secondaryButton:hover,
.kindTertiary.colorBrand.mainActive.hovered.splitContentOpen .secondaryButton,
.kindTertiary.colorBrand.mainActive.hovered.splitContentOpen .secondaryButton:hover {
  color: var(--brand-color);
}

.kindTertiary.colorPositive.splitContentOpen .secondaryButton,
.kindTertiary.colorPositive:hover .secondaryButton,
.kindTertiary.colorPositive:focus .secondaryButton {
  border-color: transparent;
  background-color: var(--positive-color-selected);
}

.kindTertiary.colorPositive.splitContentOpen .mainButton,
.kindTertiary.colorPositive:hover .mainButton,
.kindTertiary.colorPositive:focus .mainButton {
  background-color: var(--positive-color-selected);
}

.kindTertiary.colorNegative.splitContentOpen .secondaryButton,
.kindTertiary.colorNegative:hover .secondaryButton,
.kindTertiary.colorNegative:focus .secondaryButton {
  border-color: transparent;
  background-color: var(--negative-color-selected);
}

.kindTertiary.colorNegative.splitContentOpen .mainButton,
.kindTertiary.colorNegative:hover .mainButton,
.kindTertiary.colorNegative:focus .mainButton {
  background-color: var(--negative-color-selected);
}

.kindTertiary .secondaryButton {
  border-left: 1px solid transparent;
  margin-left: 1px;
}

.kindPrimary.disabled .secondaryButton {
  border-left: 1px solid;
  border-color: var(--ui-border-color);
}

.kindPrimary:not(.disabled).colorPrimary.mainActive .secondaryButton {
  background-color: var(--primary-hover-color);
  border-color: var(--text-color-on-primary);
}

.kindPrimary:not(.disabled).colorPrimary .secondaryButton {
  border-left: 1px solid;
  border-color: var(--primary-hover-color);
}

.kindPrimary:not(.disabled).colorBrand.mainActive .secondaryButton {
  background-color: var(--brand-hover-color);
  border-color: var(--text-color-on-primary);
}

.kindPrimary:not(.disabled).colorBrand .secondaryButton {
  border-left: 1px solid;
  border-color: var(--brand-hover-color);
}

.kindPrimary:not(.disabled).colorPositive .secondaryButton {
  border-left: 1px solid;
  border-color: var(--positive-color-hover);
}

.kindPrimary:not(.disabled).colorNegative .secondaryButton {
  border-left: 1px solid;
  border-color: var(--negative-color-hover);
}

.kindPrimary:not(.disabled).colorOnPrimaryColor .secondaryButton {
  border-left: 1px solid;
  border-color: var(--color-ui_grey);
}

.kindSecondary .mainButton {
  border-right: none;
}

.kindSecondary.colorPrimary.mainActive .mainButton {
  background-color: var(--primary-selected-color);
}

.kindSecondary.colorPrimary.mainActive .secondaryButton {
  background-color: var(--primary-selected-color);
  border-color: var(--primary-color);
}

.kindSecondary.colorPrimary.splitContentOpen .secondaryButton,
.kindSecondary.colorPrimary.splitContentOpen .secondaryButton:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: var(--primary-selected-color);
}

.kindSecondary.colorBrand.mainActive .mainButton {
  background-color: var(--brand-selected-color);
}

.kindSecondary.colorBrand.mainActive .secondaryButton {
  background-color: var(--brand-selected-color);
  border-color: var(--brand-color);
}

.kindSecondary.colorBrand.splitContentOpen .secondaryButton,
.kindSecondary.colorBrand.splitContentOpen .secondaryButton:hover {
  color: var(--brand-color);
  border-color: var(--brand-color);
  background-color: var(--brand-selected-color);
}
