@use "../Clickable/Clickable.module" as clickable;
@import "../../styles/typography";
@import "../../styles/keyframes";

.clickable {
  @include clickable.clickable;
}

.label {
  @include smoothing-text;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  padding: 2px var(--spacing-small);
  position: relative;
}

.withLeg {
  border-radius: var(--border-radius-small) var(--border-radius-small) var(--border-radius-small) 0;
}

.legWrapper {
  position: absolute;
  height: 8px;
  top: 100%;
  left: 0;
  display: inline-flex;
}

.withAnimation {
  animation: label-spin-in-emphasized var(--motion-expressive-short) var(--motion-timing-emphasize);
  transform-origin: bottom left;
}

.kindFill {
  &.colorPrimary {
    background-color: var(--primary-color);

    svg path {
      fill: var(--primary-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--primary-hover-color);
    }
  }

  &.colorDark {
    background-color: var(--inverted-color-background);

    svg path {
      fill: var(--inverted-color-background);
    }
  }

  &.colorNegative {
    background-color: var(--negative-color);

    svg path {
      fill: var(--negative-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--negative-color-hover);
    }
  }

  &.colorPositive {
    background-color: var(--positive-color);

    svg path {
      fill: var(--positive-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--positive-color-hover);
    }
  }
}

.kindLine {
  border: 1px solid currentColor;
  padding: 1px var(--spacing-small);

  .legWrapper {
    left: -1px;
    top: calc(100% + 1px);
  }

  &.colorPrimary {
    color: var(--primary-color);

    svg path {
      fill: var(--primary-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--primary-background-hover-color);
    }
  }

  &.colorDark {
    color: var(--inverted-color-background);

    svg path {
      fill: var(--inverted-color-background);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--primary-background-hover-color);
    }
  }

  &.colorNegative {
    color: var(--negative-color);

    svg path {
      fill: var(--negative-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--negative-color-selected);
    }
  }

  &.colorPositive {
    color: var(--positive-color);

    svg path {
      fill: var(--positive-color);
    }

    .clickable:hover &,
    .clickable:active & {
      background-color: var(--positive-color-selected);
    }
  }
}

@include keyframe(label-spin-in-emphasized) {
  @include spin-in-emphasized();
}
