.tipseenMedia {
  width: 100%;
  overflow: hidden;
  display: flex;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  z-index: 1;
}

.colorInverted {
  border-color: var(--inverted-color-background);
}