@mixin vibe-text($type-prefix, $weight-type) {
  font: var(--font-#{$type-prefix}-#{$weight-type});
}

@mixin vibe-heading($type-prefix, $weight-type) {
  font: var(--font-#{$type-prefix}-#{$weight-type});
  letter-spacing: var(--letter-spacing-#{$type-prefix}-#{$weight-type});
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
}

@mixin line-clamp($lines: 1le) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  white-space: initial; // doesn't work with white-space: no-wrap
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
