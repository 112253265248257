// Monday ui styles dependencies
@import "~monday-ui-style/src/common";
@import "~monday-ui-style/dist/mixins";

// Utilities map
@import "../../styles/utilities/utilities-settings";

.box {
  overflow: hidden;

  &.scrollable {
    /* TODO: make default in the next major version */
    overflow: auto;
    @include scroller;
  }
}

// Generating Utility class locally for Box component
@include utility-class-factory($utilities-modules, $export: "modules");
