body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.textarea {
  border-radius: var(--border-radius-small);
  border-color: var(--ui-border-color);
  border-width: 1px;
  border-style: solid;
  outline: none;
  resize: none;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
}

.textarea:focus {
  border-color: var(--color-basic_blue);
}
